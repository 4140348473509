<template>
  <div>
    <div class="swipper-container">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item v-for="(item, index) in bannerImgs" :key="index" loop>
          <div class="banner-imgs">
            <img :src="item" />
            <div v-if="index===0" class="swiper-text">
              <p class="swiper-text-1 leftP">“海河·谛听”言语交互大模型</p>
              <p class="swiper-text-2">聆听弦外之意</p>
              <p class="swiper-text-3">Haihe Diting-A LARGE LANGUANGE MODEL CAPABLE OF PERCEIVING OVERTONES IN SPEECH INTERACTIONS</p>
            </div>
            <div v-if="index===1" class="swiper-text">
              <p class="swiper-text-1">数字智慧&nbsp;&nbsp;言语畅享</p>
              <p class="swiper-text-2">一站式解决您的所有问题</p>
              <p class="swiper-text-3">LEADER IN THE FIELD OF APPREHENDING AND OUTPUTTING NATURAL LANGUANGE AND PROVIDER IF RELATED ONE-STOP SOLUTIONS</p>
            </div>
            <div v-if="index===2" class="swiper-text">
              <p class="swiper-text-1">个性化的定制服务</p>
              <p class="swiper-text-2">为产品赋予独特的品质</p>
              <p class="swiper-text-3">SUPPLIER OF PERSONALIZED AND CUSTOMIZED SERVICES TO MAKE YOUR PRODUCTS UNIQUE</p>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="page-container-white">
      <div class="page-title-container content-container">
        <div class="page-title">
          <p>核心技术</p>
          <p>CORE TECHNOLOGY</p>
        </div>
        <div class="hxjs-body">
          <div>
            <img src="~@/assets/imgs/index/core_diting.png">
            <p>“海河·谛听”言语交互大模型</p>
            <p><span>“以人的机理为蓝本”，将言语信息结合多学科，实现言语意图深度理解</span></p>
          </div>
          <div>
            <img src="~@/assets/imgs/index/core_yuyin.png">
            <p>语音识别</p>
            <p><span>响应迅速，转写高效，为客户提供多语种，高准确率的语音转文字服务</span></p>
          </div>
          <div>
            <img src="~@/assets/imgs/index/core_hecheng.png">
            <p>语音合成</p>
            <p><span>采用业界领先端到端合成技术，满足用户多语种、多场景、多音色的语音播报需求</span></p>
          </div>
          <div>
            <img src="~@/assets/imgs/index/core_shengwen.png">
            <p>声纹识别</p>
            <p><span>技术累积深厚，面向用户安全性要求，打造高可靠性的身份识别技术</span></p>
          </div>
          <div>
            <img src="~@/assets/imgs/index/core_fanyi.png">
            <p>机器翻译</p>
            <p><span>拥有业界领先的高准确率翻译技术，满足多语言、多场景的翻译需求</span></p>
          </div>
          <div>
            <img src="~@/assets/imgs/index/core_duihua.png">
            <p>对话系统</p>
            <p><span>基于深度学习和大数据，提供定制化的人机对话技术，适配多种应用场景，提高交互体验</span></p>
          </div>
        </div>
      </div>
    </div>
    <div class="page-container-grey">
      <div class="page-title-container content-container">
        <div class="page-title">
          <p>主要产品</p>
          <p>MAIN PRODUCTS</p>
        </div>
        <div class="zycp-body">
          <div>
            <img class="zycp-img" src="~@/assets/imgs/index/main_yd.jpg">
            <div class="zycp-text"><span>“海河·谛听”言语交互大模型</span></div>
            <div class="zycp-hide">
              <div><p>“海河·谛听”言语交互大模型</p></div>
              <div><p>海河·谛听是一款言语交互意图深度理解大模型，可以结合语音文本内容、情感等要素进行精准的聚合式生成对话，适用多个领域</p></div>
              <div><el-button @click="$router.push('/diting')">了解更多</el-button></div>
            </div>
          </div>
          <div>
            <img class="zycp-img" src="~@/assets/imgs/index/main_pt.jpg">
            <div class="zycp-text"><span>慧言AI平台</span></div>
            <div class="zycp-hide">
              <div><p>慧言AI平台</p></div>
              <div><p>为您提供智能语音、翻译、对话等能力，支持云端调用，为用户和企业提供AI赋能</p></div>
              <div><el-button @click="$router.push('/ai')">了解更多</el-button></div>
            </div>
          </div>
          <div>
            <img class="zycp-img" src="~@/assets/imgs/index/main_hy.jpg">
            <div class="zycp-text"><span>智能会议系统</span></div>
            <div class="zycp-hide">
              <div><p>智能会议系统</p></div>
              <div><p>提供会议场景下语音转文字和翻译服务，帮助用户快速高效实现会议大屏、整理会议内容</p></div>
              <div><el-button @click="$router.push('/meeting')">了解更多</el-button></div>
            </div>
          </div>
          <div>
            <img class="zycp-img" src="~@/assets/imgs/index/main_sy.jpg">
            <div class="zycp-text"><span>智能语音私有化部署</span></div>
            <div class="zycp-hide">
              <div><p>智能语音私有化部署</p></div>
              <div><p>提供AI技术私有化部署，支持国产化，保障数据安全，为企业提供智能化服务</p></div>
              <div><el-button @click="$router.push('/privatization')">了解更多</el-button></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-container-white">
      <div class="page-title-container content-container">
        <div class="page-title">
          <p>解决方案</p>
          <p>SOLUTION DESCRIPTION</p>
        </div>
        <div class="jjfa-body">
          <div>
            <div>
              <img src="~@/assets/imgs/index/so_icon_jy.png">
              <p>大模型+教育</p>
              <i></i>
              <p><span>采用声纹识别、语音识别等多技术协同，为用户提供高可靠性的教育体验，实时识别课堂中的讲课内容，且智能区分教师和学生，让大模型为教育赋能</span></p>
            </div>
          </div>
          <div>
            <div>
              <img src="~@/assets/imgs/index/so_icon_bg.png">
              <p>大模型+办公</p>
              <i></i>
              <p><span>从日常协作到会议记录，智能语音服务为您提供高效办公体验，讲方案等于写方案，不用担心忘记重点</span></p>
            </div>
          </div>
          <div>
            <div>
              <img src="~@/assets/imgs/index/so_icon_jr.png">
              <p>大模型+金融</p>
              <i></i>
              <p><span>通过自然语言处理技术和语音识别技术，为金融机构提供智能客服系统、营销系统等，提高客户服务质量及营销效果</span></p>
            </div>
          </div>
          <div>
            <div>
              <img src="~@/assets/imgs/index/so_icon_yl.png">
              <p>大模型+医疗</p>
              <i></i>
              <p><span>通过AI智能服务，为患者提供更为轻松便捷的诊疗流程服务将医护人员从输入电子病历的工作中解放出来，我们为您记录重要信息</span></p>
            </div>
          </div>
          <div>
            <div>
              <img src="~@/assets/imgs/index/so_icon_jj.png">
              <p>大模型+家居</p>
              <i></i>
              <p><span>打造全方位家庭智慧系统，结合智能语音交互技术提供智能家居解决方案，沙发就是您的王位，只需下达指令，一切随您而动</span></p>
            </div>
          </div>
          <div>
            <div>
              <img src="~@/assets/imgs/index/so_icon_cx.png">
              <p>大模型+出行</p>
              <i></i>
              <p><span>高精度车载语音识别、准确语义理解，为用户提供流畅的车载交互体验</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-container-white slys-bg">
      <div class="page-title-container page-slys">
        <div class="page-title-right">
          <p>实力优势</p>
          <p>STRENGTH & ADVANTAGE</p>
        </div>
        <div class="slys-body">
          <div>
            <div><img src="~@/assets/imgs/index/str_td.jpg"></div>
            <div>
              <p>优秀的研发团队</p>
              <p>专注于智能语音交互技术的研发和应用，打造优秀的人工智能产品和解决方案</p>
            </div>
          </div>
          <div>
            <div><img src="~@/assets/imgs/index/str_sj.jpg"></div>
            <div>
              <p>丰富的数据支持</p>
              <p>拥有海量训练数据及专业数据处理团队，支持中英日韩等多语种的识别、翻译、合成能力</p>
            </div>
          </div>
          <div>
            <div><img src="~@/assets/imgs/index/str_yt.jpg"></div>
            <div>
              <p>强大的技术依托</p>
              <p>依托于天津市认知计算与应用重点实验室及相关专家</p>
            </div>
          </div>
          <div>
            <div><img src="~@/assets/imgs/index/str_js.jpg"></div>
            <div>
              <p>深厚的技术积累</p>
              <p>团队成员曾多次获得语音翻译比赛世界冠军，拥有上百项软件著作权、专利、论文等自主知识产权和科研成果</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-container-white">
      <div class="page-title-container content-container" style="margin-bottom: 0;">
        <div class="page-title">
          <p>公司简介</p>
          <p>COMPANY PROFILE</p>
        </div>
        <div class="gsjj-body">
          <div><img src="~@/assets/imgs/index/com_img.jpg"></div>
          <div>
            <p><span>慧言科技（天津）有限公司是一家业内领先的言语交互高新技术企业。公司基于自主可控“海河·谛听”言语交互意图理解大模型，研发语音识别、语音生成、语义理解、文本生成、知识问答、机器翻译、声纹识别等全链路言语交互技术，支持中英日韩法西俄德维藏泰越等20余个语种，言语交互技术达到国内领先。同时，公司为合作伙伴提供大模型+教育、大模型+办公、大模型+金融等多行业综合解决方案。</span></p>
            <div><el-button @click="$router.push('/introduction')">了解更多</el-button></div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-container-white">
      <div class="page-title-container content-container">
        <div class="page-title">
          <p>合作伙伴</p>
          <p>COOPERATIVE PARTNER</p>
        </div>
        <div class="hzhb-body">
          <div><img src="~@/assets/imgs/index/partners/partners_alibaba.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_dingding.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_oppo.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_huaweiyun.png"></div>
<!--          <div><img src="~@/assets/imgs/index/partners/partners_baidu.png"></div>-->
          <div><img src="~@/assets/imgs/index/partners/partners_didi.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_tiandy.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_huizhixinyuan.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_xinyikeji.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_gtcom.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_wiseweb.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_dasoundgen.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_newland.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_casc.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_guojiadianwang.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_zhongqizhognxin.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_cashway.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_innovem.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_aoweiya.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_tianjindaxue.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_tianjinligongdaxue.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_aodasike.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_shaunghoukeji.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_xinghongye.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_tog.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_huayindianzi.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_daotianqing.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_huitong.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_yingqiao.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_peredoc.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_zhushihuishe.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_tami.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_tmxmall.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_yunyi.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_maorenkeji.png"></div>
          <div><img src="~@/assets/imgs/index/partners/partners_tianjinguangbodianshitai.png"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import banner1 from '../assets/imgs/index/home_poster01.png'
import banner2 from '../assets/imgs/index/home_poster02.jpg'
import banner3 from '../assets/imgs/index/home_poster03.jpg'
export default {
  name: 'index',
  data() {
    return {
      bannerImgs: [banner1, banner2, banner3]
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="less">
.el-carousel {
  height: 100%;
  /deep/ .el-carousel__container {
    height: 100%;
  }
  /deep/ .el-carousel__button {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 10px;
    background-color: transparent;
    border: 3px solid #fff;
  }
  /deep/ .el-carousel__arrow {
    width: 50px;
    height: 50px;
    background-color: transparent;
  }
  /deep/ .el-icon-arrow-right, /deep/ .el-icon-arrow-left {
    font-size: 60px;
    color: #007aff;
  }
  /deep/ .el-carousel__indicator.is-active {
    .el-carousel__button {
      background-color: #fff;
    }
  }
}

.banner-imgs {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  img {
    height: 100%;
  }
  .swiper-text {
    width: 1050px;
    position: absolute;
    left: 360px;
    margin-top: 130px;
    color: #fff;
  }
  .swiper-text-1{
    margin-bottom: 16px;
    font-family: 'siyuan-medium', serif;
    font-size: 50px;
  }
  .leftP {
    margin-left: -30px;
  }
  .swiper-text-2{
    width: 56%;
    margin-bottom: 36px;
    font-family: 'siyuan-normal', serif;
    font-size: 20px;
  }
  .swiper-text-3{
    width: 55%;
    font-size: 14px;
  }
}
.swipper-container {
  height: 600px;
}
.jjfa-body>div:nth-of-type(1) {
  background:  url('../assets/imgs/index/so_jy.jpg') no-repeat center center;;
}
.jjfa-body>div:nth-of-type(2) {
  background:  url('../assets/imgs/index/so_bg.jpg') no-repeat center center;;
}
.jjfa-body>div:nth-of-type(3) {
  background:  url('../assets/imgs/index/so_jr.png') no-repeat center center;;
}
.jjfa-body>div:nth-of-type(4) {
  background:  url('../assets/imgs/index/so_yl.jpg') no-repeat center center;;
}
.jjfa-body>div:nth-of-type(5) {
  background:  url('../assets/imgs/index/so_jj.jpg') no-repeat center center;;
}
.jjfa-body>div:nth-of-type(6) {
  background:  url('../assets/imgs/index/so_cx.jpg') no-repeat center center;;
}
.slys-bg {
  width: 100%;
  height: 562px;
  background: url(../assets/imgs/index/stre_zt.jpg) no-repeat center center;
  background-size: 100% 100%;
}
</style>